import React from "react"
import s from "./CopyableText.module.css"
import { NotificationService } from "../../features/NotificationService/NotificationService"

const CopyableText: React.FC<{ children: string }> = ({ children }) => {
  const handleCopy = () => {
    navigator.clipboard
      .writeText(children)
      .then(() => {
        NotificationService.showNotification({
          message: "Текст скопирован в буфер обмена",
          level: "success",
          hideAfter: 2000,
        })
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: "Ошибка при копировании текста",
          level: "error",
          errorBody: error,
          hideAfter: 2000,
        })
      })
  }

  return (
    <div>
      <p className={s.text}>{children}</p>
      <button onClick={handleCopy}>Скопировать текст</button>
    </div>
  )
}

export default CopyableText
