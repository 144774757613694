import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type initialStateType = {
  wildberries: {
    lastStoksUpdated: string
    lastPricesUpdated: string
    status: "Ошибка" | "Успешно" | "Ошибка в файлах" | "В процессе"
    isCurrentDataUploaded: boolean
  }
}

const initialState: initialStateType = {
  wildberries: {
    lastStoksUpdated: "",
    lastPricesUpdated: "",
    status: "Ошибка в файлах",
    isCurrentDataUploaded: false,
  },
}

const updateStoksPodruzhkaSlice = createSlice({
  name: "updateStoksPodruzhka",
  initialState,
  reducers: {
    setUpdateStoksPodruzhkaState: (
      state,
      action: PayloadAction<initialStateType>,
    ) => {
      state.wildberries = action.payload.wildberries
    },
  },
})

export const selectUpdateStoksPodruzhkaStore = (state: RootState) =>
  state.updateStoksPodruzhka

export default updateStoksPodruzhkaSlice.reducer

export const { setUpdateStoksPodruzhkaState } =
  updateStoksPodruzhkaSlice.actions
