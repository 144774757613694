import { ChangeEvent, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectUpdateStoksPodruzhkaStore } from "../../app/reducers/updateStoksPodruzhka"
import {
  updateStoksPodruzhkaState,
  uploadCurrentDataPodruzhka,
} from "../UpdateStoksPodrugka/UpdateStoksPodruzhka"
import s from "./FormPodrOrder.module.css"
import { Modal } from "../../features/Modal/Modal"
import BaseApi from "../../api/api"
import CopyableText from "../CopyableText/CopyableText"

type FormPodrOrderProps = {
  supplyId?: string
}

const getAdderCode = (
  ordersInfo: { productId: string; quantity: number }[],
): string => {
  const resps = ordersInfo.map(
    (order) => `.then(() => respCb(${order.productId}, ${order.quantity}))`,
  )
  return `$('html').css({cursor:'wait'});$('body').css({pointerEvents:"none", opacity:.3});const pauseCb = () => new Promise(resolve => setTimeout(resolve, 1000));const respCb=(productId, quantity)=>$.ajax({type:"POST",url:'/ajax/add2basket.php',data:{id:productId,quantity:quantity}});new Promise(resolve => setTimeout(resolve, 200))${resps.join(
    ".then(pauseCb)",
  )}.then(() => {location.reload();}).catch(()=>{alert('Ошибка при добавлении заказов в корзину'); location.reload();})`
}

export const FormPodrOrder = ({ supplyId }: FormPodrOrderProps) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(updateStoksPodruzhkaState())
  }, [])

  const [isOpenModal, setIsOpenModal] = useState(false)
  const updateStoksStore = useAppSelector(selectUpdateStoksPodruzhkaStore)
  const { isCurrentDataUploaded } = updateStoksStore?.wildberries || {}
  const [ordersInfo, setOrdersInfo] = useState<
    { productId: string; quantity: number }[]
  >([])
  const [errArticles, setErrArticles] = useState<string[]>([])

  const onOpenModal = () => {
    if (isCurrentDataUploaded && supplyId) {
      BaseApi.getSuppliesPodrOrdersInfo(supplyId).then((res) => {
        const responseData = res.data
        const { success, data } = responseData || {}
        const { orders, errArticles } = data || {}
        if (success && Array.isArray(orders)) {
          setIsOpenModal(true)
          setOrdersInfo(orders)
          setErrArticles(errArticles)
        }
      })
    }
  }

  return (
    <div className={s.content}>
      <strong>Сформировать заказ на Подружку</strong>
      {!isCurrentDataUploaded && (
        <>
          <p>
            Чтобы сформировать заказ, загрузите файл перекодировки "Подружки"
          </p>
          <label className={`btn`} htmlFor={s.fileInp}>
            Загрузить перекод Подружки
          </label>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const file = e.target.files?.[0]
              if (file) {
                dispatch(uploadCurrentDataPodruzhka(file))
              }
            }}
            type="file"
            accept=".xlsx"
            id={s.fileInp}
          />
        </>
      )}
      <button
        disabled={!isCurrentDataUploaded || !supplyId}
        onClick={onOpenModal}
      >
        Сформировать заказа Подружки
      </button>
      <Modal
        header="Добавить заказы в корзину Подружки"
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onOk={() => setIsOpenModal(false)}
        size="xl"
        hideCancelBtn
        content={
          <div>
            {ordersInfo.length > 0 && (
              <>
                <strong>
                  Чтобы добавить заказы в корзину, выполните следующий код в
                  консоли браузера, на странице сайта Подружки мобильной версии
                  (в обычной версии блочат).{" "}
                  <a href="https://www.m.podrygka.ru/" target="_blank">
                    ССЫЛКА НА САЙТ ПОДРУЖКИ
                  </a>
                </strong>
                <CopyableText>{getAdderCode(ordersInfo)}</CopyableText>
              </>
            )}
            {errArticles.length > 0 && (
              <div>
                <strong>Артикулы, которые не найдены:</strong>
                <ol>
                  {errArticles.map((article, index) => (
                    <li key={index}>{article}</li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}
