import React, { Component } from "react"

import s from "./NotificationServiceStyles.module.css"
import { NotificationType } from "./NotificationTypes"
import { Notification } from "./Notification"
import { createRoot } from "react-dom/client"

export class NotificationService extends Component {
  private static notificationWrapper: HTMLDivElement | null = null
  public static showNotification<T extends NotificationType>(notification: T) {
    const span = document.createElement("span")
    const spanRoot = createRoot(span)
    NotificationService.notificationWrapper?.insertBefore(
      span,
      NotificationService.notificationWrapper?.firstChild,
    )
    spanRoot.render(
      <Notification
        level={notification.level}
        message={notification.message}
        errorBody={notification.errorBody}
      />,
    )
    setTimeout(() => {
      spanRoot.unmount()
      span.remove()
    }, notification.hideAfter || 30000)
  }
  render() {
    return (
      <div
        className={s.notificationWrapper}
        ref={(el) => (NotificationService.notificationWrapper = el)}
      ></div>
    )
  }
}
