import React, { useEffect, useState } from "react"
import { PriceSetting } from "./PriceSetting"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import BaseApi from "../../api/api"
import { AppDispatch } from "../../app/store"
import {
  selectSettings,
  setSettingsState,
  setPriceParams,
  PriceType,
  selectActiveShops,
} from "../../app/reducers/settingsReducer"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import s from "./Settings.module.css"
import { Tabs } from "../Tabs/Tabs"
import { TabContent } from "../Tab/TabContent"

export const updateSettingsState = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.settings()
      .then((response) => {
        if (response.data) {
          dispatch(setSettingsState(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const updatePricesParams = (shop: string, price: PriceType) => {
  return async (dispatch: AppDispatch) => {
    BaseApi.updatePriceParams(shop, price)
      .then((response) => {
        dispatch(updateSettingsState())
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        dispatch(updateSettingsState())
        // Обработайте ошибку загрузки файла
      })
  }
}

export const Settings = () => {
  const dispatch = useAppDispatch()
  // useEffect(() => {
  //   dispatch(updateSettingsState())
  // }, [])

  const settingspriceState = useAppSelector(selectSettings)
  const activeShops = useAppSelector(selectActiveShops)

  return (
    <div className={s.settings}>
      <Tabs>
        {activeShops.map((shopName) => {
          const {
            margin,
            profit,
            logistic,
            ff,
            coef,
            tax,
            sale,
            priemka,
            ekv,
            mila,
          } = settingspriceState[shopName]?.price || {}
          return (
            <TabContent
              key={`tabcontent-${shopName}`}
              name={`Калькулятор для ${shopName}`}
            >
              <PriceSetting
                onSave={(args: PriceType) =>
                  dispatch(updatePricesParams(shopName, args))
                }
                margin={margin}
                profit={profit}
                logistic={logistic}
                ff={ff}
                coef={coef}
                tax={tax}
                sale={sale}
                priemka={priemka}
                ekv={ekv}
                mila={mila}
                shop={shopName}
                onChange={(args: PriceType) =>
                  dispatch(
                    setPriceParams({ shop: shopName, price: args as any }),
                  )
                }
              />
            </TabContent>
          )
        })}
      </Tabs>
    </div>
  )
}
