import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
  IOrder,
  ISupply,
  selectOrdersStore,
  setFormOrders,
  setOrderState,
  setSupplies,
} from "../../app/reducers/ordersReducer"

import s from "./Supplies.module.css"
import { AppDispatch } from "../../app/store"
import BaseApi from "../../api/api"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import { Modal } from "../../features/Modal/Modal"
import { ModalOrderList } from "./ModalOrderList"
import { FormXlsxForOrderIcon } from "../FormXlsxForOrderIcon/FormXlsxForOrderIcon"
import { FormPodrOrder } from "../FormPodrOrder/FormPodrOrder"

const getSuppliesWithOrders = (supplyId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await BaseApi.getSuppliesWithOrders(supplyId)
      dispatch(
        setSupplies({
          supplies: response?.data.supplies,
          lastDateUpdatedSupplies:
            (response?.data.lastDateUpdatedSupplies &&
              new Date(
                response?.data.lastDateUpdatedSupplies,
              ).toLocaleString()) ||
            response?.data.lastDateUpdatedSupplies,
        }),
      )
    } catch (e: any) {
      NotificationService.showNotification({
        message: e?.message,
        level: "error",
        errorBody: e,
      })
    }
  }
}

const updateSupplies = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.updateSupplies()
      .then((response) => {
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
        dispatch(
          setSupplies({
            supplies: response?.data.supplies,
            lastDateUpdatedSupplies:
              (response?.data.lastDateUpdatedSupplies &&
                new Date(
                  response?.data.lastDateUpdatedSupplies,
                ).toLocaleString()) ||
              response?.data.lastDateUpdatedSupplies,
          }),
        )
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: error?.message,
          level: "error",
          errorBody: error,
        })
      })
  }
}

const getOrdersState = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.getOrdersState()
      .then((response) => {
        dispatch(setOrderState(response?.data))
      })
      .catch((error) => {
        dispatch(
          setOrderState({
            orders: [],
            supplies: [],
            lastDateUpdatedSupplies: undefined,
            storages: [],
            ozonStorages: [],
          }),
        )
        console.error(error)
      })
  }
}

const formOrderListForKatren = (supplyId: string) => {
  return async (dispatch: AppDispatch) => {
    BaseApi.formOrderListForKatren(supplyId)
      .then((response) => {
        if (response.data.success) {
          dispatch(setFormOrders(response.data.records))
        }
      })
      .catch((error) => {
        NotificationService.showNotification({
          message: error?.message,
          level: "error",
          errorBody: error,
        })
      })
  }
}

export const Supplies = () => {
  useEffect(() => {
    dispatch(getOrdersState())
  }, [])
  const dispatch = useAppDispatch()
  const ordersStore = useAppSelector(selectOrdersStore)
  const { formOrders, storages, supplies, lastDateUpdatedSupplies } =
    ordersStore
  const [selectedSupplyId, setSelectedSupplyId] = useState<string>("")
  const [isOpenModalFormOrders, setIsOpenModalFormOrders] = useState(false)
  return (
    <div>
      <div>
        <h5>Формирование файла поставок для заказа у дистрьебьютера</h5>
      </div>
      <div>
        <div>
          <div>
            <div>
              <select
                name="supplies"
                id="supplies-select"
                value={selectedSupplyId || ""}
                onChange={(e) => {
                  const supp = supplies.find((el) => el.id === e.target.value)
                  setSelectedSupplyId(e.target.value)
                  if (!supp?.orders) {
                    dispatch(getSuppliesWithOrders(e.target.value))
                  }
                }}
              >
                <option value={""}>Выбрать поставку</option>
                {supplies.map((supp) => (
                  <option value={supp.id} key={supp.name}>
                    {supp.name}
                  </option>
                ))}
              </select>
              <button
                className={s.updateSupplies}
                onClick={() => {
                  dispatch(updateSupplies()).then(() => setSelectedSupplyId(""))
                }}
              >
                Обновить поставки
                {lastDateUpdatedSupplies &&
                  ` (обновлено: ${lastDateUpdatedSupplies})`}
              </button>
            </div>
          </div>
          <div>
            <div>
              <h5>Сборочные задания поставки</h5>
              <div className={s.content}>
                <table>
                  <thead>
                    <tr>
                      <th>№ задания</th>
                      <th>Создано</th>
                      <th>Склад</th>
                      <th>Артикул продавца</th>
                      <th>Артикул WB</th>
                      <th>Цена</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(
                      (selectedSupplyId &&
                        supplies.find((el) => el.id === selectedSupplyId)
                          ?.orders) ||
                      []
                    ).map((el, i) => {
                      return (
                        <tr key={el.article + "-order-supp-" + i}>
                          <td>{el.id}</td>
                          <td>{new Date(el.createdAt).toLocaleString()}</td>
                          <td>{el.warehouseId}</td>
                          <td>{el.article}</td>
                          <td>{el.nmId}</td>
                          <td>{el.convertedPrice / 100}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className={s.buttonsWrap}>
                  <button
                    disabled={!selectedSupplyId}
                    onClick={() => {
                      dispatch(formOrderListForKatren(selectedSupplyId)).then(
                        () => {
                          setIsOpenModalFormOrders(true)
                        },
                      )
                    }}
                  >
                    Сформировать файл для заказа из Katren
                  </button>
                  <FormXlsxForOrderIcon
                    orders={
                      supplies.find((el) => el.id === selectedSupplyId)?.orders
                    }
                  />
                  <FormPodrOrder supplyId={selectedSupplyId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpenModalFormOrders}
        onClose={() => {
          setIsOpenModalFormOrders(false)
        }}
        header={"Сформированный заказ"}
        onOk={() => {
          BaseApi.downloadFormOrderListForKatren()
        }}
        content={<ModalOrderList formOrders={formOrders || []} />}
        size="auto"
        okContent="Скачать"
      />
    </div>
  )
}
